import { ReactNode, SyntheticEvent, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, Button, Checkbox, Chip, Dialog, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Tooltip, Typography } from "@mui/material";
import { Asset, ServiceLine, StarlinkAvailabilityAlert, User } from "../types";
import { extractEmails } from "../../../utils/util";

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';

import teamsWebhookHelpDoc from "../../../asset/user_guide/TeamsWebhookCreateRemove.pdf";
import { getAllServiceLines, getAllServiceLinesParameters } from "../slice";


interface StarlinkAvailabilityAlertDialogProps {
    authReducer: any;
    errorReducer: any;
    starlinkCloud: any;
    starlinkReports: any;
    serviceLineToEdit: any | null;
    serviceLines: ServiceLine[];
    open: boolean;
    onClose: () => void;
    onSave: (quotaAlerts: StarlinkAvailabilityAlert[]) => void;
    getAllServiceLines: (parameters: getAllServiceLinesParameters) => void;
}

function QuotaAlertDialog(props: StarlinkAvailabilityAlertDialogProps) {

    const { authReducer, errorReducer, starlinkCloud, starlinkReports, serviceLineToEdit, serviceLines, open, onClose, onSave, getAllServiceLines } = props;
    let filteredServiceLines = serviceLines.filter((item, index, array) => array.findIndex(t => t.nickname === item.nickname) === index)

    const [selectedServiceLines, setSelectedServiceLine] = useState<ServiceLine[]>([])
    const [alertingThreshold, setAlertingThreshold] = useState<any>(serviceLineToEdit !== null && serviceLineToEdit?.availabilityAlert.length > 0 && serviceLineToEdit?.availabilityAlert[0]?.alertingThreshold ? serviceLineToEdit.availabilityAlert[0].alertingThreshold : '15m');
    const [notificationMethods, setNotificationMethods] = useState<any>(serviceLineToEdit !== null && serviceLineToEdit?.availabilityAlert.length > 0 && serviceLineToEdit?.availabilityAlert[0]?.notificationMethods ? serviceLineToEdit.availabilityAlert[0].notificationMethods : []);
    const [usersToNotify, setUsersToNotify] = useState<any>(serviceLineToEdit !== null && serviceLineToEdit?.availabilityAlert[0]?.usersToNotify ? serviceLineToEdit?.availabilityAlert[0]?.usersToNotify : []);
    const [valid, setValid] = useState<boolean>(false);
    const [disableActions, setDisableActions] = useState<boolean>(false);

    const handleClose = () => {
        onClose();
    }

    const handleSave = () => {
        if (selectedServiceLines) {
            onSave(selectedServiceLines.map((serviceLine: ServiceLine) => {
                return {
                    serviceLineNumber: serviceLine.serviceLineNumber,
                    alertingThreshold: alertingThreshold,
                    notificationMethods: notificationMethods,
                    usersToNotify: usersToNotify
                }
            }));
        }
    }

    const users = useMemo(() => {
        return authReducer?.usersList?.data?.records?.length > 0 ? _.orderBy(authReducer?.usersList?.data?.records?.map((u: any) => {
            let _u: User = {
                name: u?.name?.givenName + " " + u?.name?.familyName,
                email: u.userName
            };
            return _u;
        }).filter(
            (u: User) => u.name.trim() !== "" && u.email.trim() !== ""
        ), 'name', 'asc') : [];
    }, [authReducer?.usersList]);

    useEffect(() => {
        if (open) {
            setSelectedServiceLine(serviceLineToEdit ? [serviceLineToEdit] : []);
            setAlertingThreshold(serviceLineToEdit?.availabilityAlert[0]?.alertingThreshold || '5m');
            setNotificationMethods(serviceLineToEdit?.availabilityAlert[0]?.notificationMethods || []);
            setUsersToNotify(serviceLineToEdit?.availabilityAlert[0]?.usersToNotify || []);
        }
    }, [open, serviceLineToEdit]);

    useEffect(() => {
        setValid(
            selectedServiceLines.length > 0 &&
            alertingThreshold.length > 0
        );
    }, [selectedServiceLines, alertingThreshold]);

    useEffect(() => {
        setDisableActions(
            starlinkReports.settingQuotaAlert
        );
    }, [starlinkReports]);

    useEffect(() => {
        if (notificationMethods.includes("email")) {
            setUsersToNotify(usersToNotify);
        } else {
            setUsersToNotify([]);
        }
    }, [notificationMethods]);

    const handleChangeAlertingThreshold = (event: SelectChangeEvent<string>, child: ReactNode) => {
        setAlertingThreshold(event.target.value);
    }

    const handleChangeSelectedServiceLines = (event: SyntheticEvent<Element, Event>, value: unknown, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<unknown> | undefined) => {
        
        if (reason === "selectOption" || reason === "removeOption") {
            if ((value as ServiceLine[]).find(option => option.serviceLineNumber === "all")) {
              return setSelectedServiceLine(serviceLines);
            } else {
              return setSelectedServiceLine(value as ServiceLine[]);
            }
          } else if (reason === "clear") {
            setSelectedServiceLine([]);
          }
    }

    const handleChangeNotificationMethods = (event: React.ChangeEvent<HTMLInputElement>) => {
        let _value = [...notificationMethods];
        if (event.target.checked) {
            _value.push(event.target.name);
        } else {
            _value = _value.filter(nm => nm !== event.target.name);
        }
        setNotificationMethods(_value);
    };

    const handleChangeUsersToNotify = (event: SyntheticEvent<Element, Event>, value: unknown, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<unknown> | undefined) => {
        if (reason === 'selectOption' || reason === 'removeOption') {
            let _value = value as User[];
            setUsersToNotify(_value);
        } else if (reason === 'createOption') {
            let _users: User[] = [];
            let _value = value as (string | User)[];
            _value.forEach((v: string | User) => {
                if (typeof v === 'string') {
                    let _values: string[] = extractEmails(v)
                    _values.forEach(_v => {
                        let _user = users.find(u => u.email === _v);
                        if (_user) {
                            _users.push(_user);
                        } else {
                            _users.push({ name: _v, email: _v });
                        }
                    })
                } else {
                    _users.push(v);
                }
            });
            setUsersToNotify(_users);
        } else if (reason === 'clear') {
            setUsersToNotify([]);
        }

    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="service_account--dialog" maxWidth="lg" fullWidth>
            <Grid classes={{ root: 'starlink--base--padding_1x' }}>
                <Grid display={'flex'} alignItems={'center'} gap={'0.5rem'} classes={{ root: 'starlink--base--mtb_07x' }}>
                    <Typography component="div" className="starlink--base--font_1x">
                        Set Availability Alert
                    </Typography>
                    <Tooltip classes={{ tooltip: 'starlink--tooltip' }} title={
                        <Typography component={'div'} className="starlink--base--font_08x starlink--base--padding_05x">
                            Web service accounts do not include telemetry, which is used to raise availability alerts. This alert can only be set for service lines added through the Starlink Enterprise Service Account API.
                        </Typography>
                    }>
                        <InfoIcon fontSize="small" />
                    </Tooltip>
                </Grid>
                <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                    <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                        <Typography component="div" className="starlink--base--font_07x">
                            Alert Configuration
                        </Typography>
                    </Grid>
                    <Grid container spacing={1} classes={{ root: 'starlink--base--mtb_07x' }}>
                        {/* add select all option */}
                        <Grid item xs={6}>
                            <Autocomplete
                                id="service_line"
                                multiple
                                disableCloseOnSelect
                                options={serviceLines?.length > 0 ? [{ serviceLineNumber: 'all', nickname: 'Select All', availabilityAlert: { serviceLineNumber: 'all', alertingThreshold: "", notificationMethods: [], usersToNotify: [] } }, ...filteredServiceLines] as ServiceLine[] : []}
                                value={selectedServiceLines}
                                onChange={handleChangeSelectedServiceLines}
                                size="small"
                                readOnly={serviceLineToEdit !== null}
                                getOptionLabel={(option: ServiceLine) => option.nickname}
                                renderInput={(params) => <TextField {...params} label="Service Line" variant="outlined" />}
                            />
                        </Grid>
                        {/* percents without fixed options, autocomplete with chips */}
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Alert if offline for</InputLabel>
                                <Select
                                    id="alerting_threshold"
                                    value={alertingThreshold}
                                    onChange={handleChangeAlertingThreshold}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    label="Alert if offline for"
                                >
                                    <MenuItem value={'15m'}>15 Minutes</MenuItem>
                                    <MenuItem value={'1h'}>1 Hour</MenuItem>
                                    <MenuItem value={'6h'}>6 Hours</MenuItem>
                                </Select>
                            </FormControl>


                        </Grid>
                    </Grid>
                </Grid>
                <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                    <Grid classes={{ root: 'starlink--base--mtb_07x' }}>
                        <Typography component="div" className="starlink--base--font_07x">
                            Notification
                        </Typography>
                    </Grid>
                    <Grid container xs={12}>
                        <Grid item xs={12} md={2} >
                            <Checkbox size='small' name={'email'} checked={notificationMethods.includes("email")} onChange={handleChangeNotificationMethods} />
                            <Typography variant="body2" color="text.secondary" display={'inline'}>
                                Email
                            </Typography>
                        </Grid>
                        {
                            notificationMethods.includes("email") && <Grid item xs={12} md={10}>
                                <FormControl fullWidth error={usersToNotify.length === 0}>
                                    <Autocomplete
                                        id="select-users"
                                        size='small'
                                        multiple={true}
                                        className={notificationMethods.includes("email") ? "" : "disbale-user-selection"}
                                        freeSolo={true}
                                        value={usersToNotify}
                                        onChange={handleChangeUsersToNotify}
                                        options={users}
                                        getOptionLabel={(option) => {
                                            let _option = option as User;
                                            return _option.name !== "" ? _option.name : _option.email
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Send to (via Email)" variant='outlined' error={usersToNotify.length === 0} />}
                                        renderOption={(props, option:any) => <li {...props} key={option.email}>{option.name !== "" ? option.name : option.email}</li>}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option:any, index) => (
                                                <Chip size='small' label={option.name} {...getTagProps({ index })} />
                                            ))
                                        }
                                        placeholder='Send to (via Email)'
                                    />
                                    {
                                        usersToNotify.length === 0 ? <FormHelperText>Please select at least one user/or type an email</FormHelperText> : null
                                    }
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                    <Grid container xs={12}>
                        <Grid item xs={12} md={2} >
                            <Checkbox size='small' name={'microsoft_teams'} checked={notificationMethods.includes("microsoft_teams")} onChange={handleChangeNotificationMethods} />
                            <Typography variant="body2" color="text.secondary" display={'inline'}>
                                Microsoft Teams
                            </Typography>
                            <IconButton size='small' onClick={() => window.open(teamsWebhookHelpDoc)}>
                                <Tooltip title="Help"><HelpOutlineIcon /></Tooltip>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid classes={{ root: 'starlink--base--flex starlink--base--mtb_07x starlink--base--flex--justify--end' }}>
                    <Button variant="outlined" className='starlink--button--cancel' onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" className='starlink--button_contained--primary' onClick={handleSave} disabled={!valid || disableActions || (notificationMethods.includes("email") && usersToNotify.length === 0)}>Save</Button>
                </Grid>
            </Grid>
        </Dialog >
    )
}

const mapStateToProps = (state: any) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    starlinkCloud: state.starlinkCloud,
    starlinkReports: state.starlinkReports,
    serviceLines: state.starlinkReports.allServiceLines?.filter(serviceLine => serviceLine?.accountType === "enterprise"),
});

export default withRouter(connect(mapStateToProps, {
    getAllServiceLines,
})(QuotaAlertDialog));
