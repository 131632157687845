import { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { useLeafletContext } from "@react-leaflet/core";
import L from "leaflet";
import './Map.css';
import "leaflet.vectorgrid";
import { SET_SELECTED_HEX } from "../../actions/actionType";

const LocationGrid = (props) => {
  const { layerContainer, map } = useLeafletContext();
  const { assets, hexOpacity, isEventsEnabled, handleHexogonClick, h3Index, setHexId, selectedHexId, zindex, resolution } = props;
  const data = assets;
  const [selectdHexLocal, setSelectedHeIdLocal] = useState(0);

  // Using a single color for all the hexagons
  const hexColor = "#3b82f6"; // You can choose any color

  const vectorGrid = useMemo(() => {
    return L.vectorGrid.slicer(data, {
      zIndex: zindex,
      vectorTileLayerStyles: {
        data: function () {
          return {
            fill: true,
            fillColor: "black",
            fillOpacity: 0.4, // Adjustable opacity
            color: 'white',
            weight: 0.3
          };
        },
      },
      getFeatureId: function (e) {
        return e?.properties?.id;
      },
      interactive: true,
    });
  }, [data, hexOpacity]);

  // Ensure event listener setup happens only once
  useEffect(() => {
    const handleClick = (e) => {
      isEventsEnabled && h3Index(e?.layer?.properties?.h3index);
      resetSelectedHex(selectdHexLocal);
      handleHexogonClick(e, resolution);
      setHexId(e?.layer?.properties?.id);
      setSelectedHeIdLocal(e?.layer?.properties?.id);
    };

    // Attach click event listener
    vectorGrid.on("click", handleClick);

    return () => {
      // Clean up the listener on unmount
      vectorGrid.off("click", handleClick);
    };
  }, [isEventsEnabled, h3Index, selectdHexLocal, handleHexogonClick, resolution, setHexId]);

  const resetSelectedHex = (hexId) => {
    vectorGrid.resetFeatureStyle(hexId);
  };

  const container = layerContainer || map;

  useEffect(() => {
    container.addLayer(vectorGrid);
    return () => {
      container.removeLayer(vectorGrid);
    };
  }, [data, hexOpacity, container, vectorGrid]);

  return null;
};

const mapStateToProps = (state) => ({
  selectedHexId: state?.products?.selectedHexId
});

const mapDispatchToProps = (dispatch) => {
  return {
    setHexId: (hexIndexId) => dispatch({ type: SET_SELECTED_HEX, item: hexIndexId })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationGrid);
