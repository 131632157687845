import { api } from "../../server/request";
import { KAPTUREV2_URL_INV, OLD_SVC_URL } from '../../config';
import { handleResponse, handleError } from "../responseUtil";
import {
  SET_LOADING,
  FETCH_VESSEL,
  SET_FETCH_USERS_LOADING,
  FETCH_USERS,
  INVITE_USER,
  INVITE_USER_LOADING,
  RESEND_INVITE,
  RESEND_INVITE_LOADING,
  GET_ORGANISATIONS,
  OU_DERIVED,
  SET_FETCH_OU_RESOURCES_LOADING,
  FETCH_OU_RESOURCES,
  OU_POLICY,
  USER_DETACH,
  USER_DETACH_LOADING,
  USER_DELETE,
  USER_DELETE_LOADING,
  DISPLAY_APPLICABLE_USER_POLICIES,
  POLICIES_DETAILS,
  UPDATE_USER,
  UPDATE_USER_LOADING,
  UPDATE_USER_ORGANIZATION,
  UPDATE_USER_ORGANIZATION_LOADING,
  ENABLE_USER_ACCOUNT,
  ENABLE_USER_ACCOUNT_LOADING,
  DISABLE_USER_ACCOUNT,
  DISABLE_USER_ACCOUNT_LOADING,
  SET_PASSWORD_User,
  SET_PASSWORD_User_LOADING,
  DETACH_RESOURCE_OU,
  NOT_ATTACH_RESOURCE_OU,
  ATTACH_RESOURCE_OU,
  ALL_TEMPLATE,
  GET_ORGANISATIONS_UNITS,
  SET_SELECTED_OU,
  SET_USER_OU,
  SET_SIDE_MENU,
  SET_SELECTED_VESSELS,
  SET_SELECTED_VESSEL,
  SET_PERMISSION_OBSERVER,
  SET_FILTERED_VESSELS,
  SET_HUB_FILTERED_VESSELS,
  SET_IS_USER_OU_HAS_SUB_OUS,
  SET_PARENT_AND_SUBDP_LIST,
  ACCOUNT_CREATE_SUB_DP,
  SET_SUB_DP_CREATE_LOADING,
  SET_IAM_LOGIN_HISTORY_TABLE,
  SET_IAM_LOGIN_HISTORY_TABLE_COUNT,
  SET_IAM_LOGIN_HISTORY_TOP_WIDGET,
  SET_IAM_LOGIN_HISTORY_FILTER_USER,
  SET_IAM_LOGIN_HISTORY_FILTER_APP,
  SET_IAM_LOGIN_HISTORY_TIMESERIES_CHART,
  SET_IAM_LOGIN_HISTORY_FILTER_DESC_LIST,
  GET_IAM_LOGIN_HISTORY_TABLE,
  GET_IAM_LOGIN_HISTORY_TABLE_COUNT,
  GET_IAM_LOGIN_HISTORY_TOP_WIDGET,
  GET_IAM_LOGIN_HISTORY_FILTER_USER,
  GET_IAM_LOGIN_HISTORY_FILTER_APP,
  GET_IAM_LOGIN_HISTORY_TIMESERIES_CHART,
  GET_IAM_LOGIN_HISTORY_FILTER_DESC_LIST,
  VERIFY_USER,
  RESET_PASSWORD,
  SET_READONLY_USER,
  IS_PRIVATE_ENABLED,
  IS_PRIVATE_LOADING,
  GET_SEARCH_DP_LIST,
  SET_DELETE_ORG_LOADING,
  GET_DELETE_ORG,
  SET_MODIFY_ORG_LOADING,
  GET_MODIFY_ORG,
  SET_DP_INFO_LOADING,
  GET_DP_INFO,
  FETCH_SERVICE_LINES,
  NEW_SUMMARY_SERVICE_ACCOUNTS
} from "../types";
import _ from "lodash";

import {
  ERROR_RESEND_INVITE,
  ERROR_INVITE_USER,
  ERROR_DETACH_USER,
  ERROR_DISPLAY_APPLICABLE_USER_POLICIES,
  ERROR_ENABLE_USER_ACCOUNT,
  ERROR_DISABLE_USER_ACCOUNT,
  ERROR_SET_PASSWORD_User,
  ERROR_FETCH_USERS,
  ERROR_FETCH_OU_RESOURCES,
  ERROR_ATTACH_RESOURCE_OU,
  ERROR_DETACH_RESOURCE_OU,
  ERROR_NOT_ATTACH_RESOURCE_OU,
  ERROR_GET_ORGANISATIONS,
  ERROR_GET_ORGANISATIONS_UNITS,
  ERROR_RESET_PASSWORD,
  ERROR_FETCH_SERVICE_LINES
} from "../error_types";
import { toast } from "react-toastify";
import { escapeRegExp } from "../../utils/util";

export const updateSelectedOu = (ou) => (dispatch) => {
  dispatch({ type: SET_SELECTED_OU, payload: ou });
};

export const updateUserOuHasSubOus = (authReducer) => (dispatch) => {
  if (!authReducer.isUserOuHasSubOus && !_.isEmpty(authReducer.getOrganisationUnitList) && authReducer.getOrganisationUnitList?.dps && Array.isArray(authReducer.getOrganisationUnitList.dps) && authReducer.getOrganisationUnitList.dps.length > 0) {
    for (let i = 0; i < authReducer.getOrganisationUnitList.dps.length; i++) {
      let dp = authReducer.getOrganisationUnitList.dps[i]
      if (!_.isEmpty(dp) && dp?.parent_of && Array.isArray(dp.parent_of) && dp.parent_of.length > 0) {
        dispatch({ type: SET_IS_USER_OU_HAS_SUB_OUS, payload: true });
        break;
      }
    }
  }
};

export const parentDpAndSubDpList = (isUserOuHasSubOus: any) => (dispatch) => {
  dispatch({ type: SET_PARENT_AND_SUBDP_LIST, payload: isUserOuHasSubOus });
};

export const updateUserOu = (ou) => (dispatch) => {
  dispatch({ type: SET_USER_OU, payload: ou });
};

export const updateIsReadOnlyUser= (isReadOnly) => (dispatch) => {
  dispatch({ type: SET_READONLY_USER, payload: isReadOnly });
};

export const updateSideMenu = (sidemenu) => (dispatch) => {
  dispatch({ type: SET_SIDE_MENU, payload: sidemenu });
};

export const updateSelectedVessels = (selectedVessels) => (dispatch) => {
  dispatch({ type: SET_SELECTED_VESSELS, payload: selectedVessels });
};

export const updatFilteredVessels = (filteredVessels) => (dispatch) => {
  dispatch({ type: SET_FILTERED_VESSELS, payload: filteredVessels });
};

export const updateHubFilteredVessels = (filteredVessels) => (dispatch) => {
  dispatch({ type: SET_HUB_FILTERED_VESSELS, payload: filteredVessels });
};

export const updateSelectedVessel = (selectedVessel) => (dispatch) => {
  dispatch({ type: SET_SELECTED_VESSEL, payload: selectedVessel });
};

export const updatePermission = (permission) => (dispatch) => {
  dispatch({ type: SET_PERMISSION_OBSERVER, payload: permission });
};

export const fetchVessel = (k4Ids, vessels, pageSize, pageNo, sortKey, sortOrder, selectedSearch) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequestForExternalApi(`${OLD_SVC_URL}/vessels?k4Ids=${"K4-0002"}&vesselNames=${"MY-Lady J"}&limit=${pageSize}&page=${"1"}`)
    // .getRequestForExternalApi(`${OLD_SVC_URL}/vessels?k4Ids=${k4Ids}&vesselNames=${vessels}&limit=${pageSize}&page=${pageNo}`)
    // .getRequestForExternalApi(`${OLD_SVC_URL}/vessels?vessels=${vessels}&pageSize=${pageSize}&pageNo=${pageNo}&sortKey=${sortKey}&sortOrder=${sortOrder}&search=${selectedSearch}`)
    .then((res) => handleResponse(res, dispatch, FETCH_VESSEL))
    .catch((err) => handleError(err, dispatch));
};

// AccountManagment
export const fetchUserListAPI = (ouName: string, page: number, limit: number, search: string) => (dispatch) => {
  dispatch({ type: SET_FETCH_USERS_LOADING, payload: true });
  api
    .getRequest(`/iam/organizationuser/user?ouName=${encodeURIComponent(ouName)}&page=${page}&limit=${limit}&search=${encodeURIComponent(escapeRegExp(search))}`)
    .then((res) => handleResponse(res, dispatch, FETCH_USERS, ERROR_FETCH_USERS))
    .catch((err) => handleError(err, dispatch, ERROR_FETCH_USERS));
};

export const displayApplicableUserPolicies = (userName: string, ouName: string) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/iam/organizationpolicy/displayApplicableUserPolicies?userName=${userName}&ouName=${encodeURIComponent(ouName)}`)
    .then((res) => handleResponse(res, dispatch, DISPLAY_APPLICABLE_USER_POLICIES, ERROR_DISPLAY_APPLICABLE_USER_POLICIES))
    .catch((err) => handleError(err, dispatch, ERROR_DISPLAY_APPLICABLE_USER_POLICIES));
};

export const policiesDetails = (ouName: string, name: string) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/iam/oupolicy/details?ouName=${encodeURIComponent(ouName)}&name=${name}`)
    .then((res) => handleResponse(res, dispatch, POLICIES_DETAILS))
    .catch((err) => handleError(err, dispatch));
};

const handleInviteResponse = (res, dispatch) => {
  if (res.status == 200) {
    toast.success("Invite sent successfully", {
      position: toast.POSITION.BOTTOM_LEFT,
    })
  } else {
    toast.error(res.message, {
      position: toast.POSITION.BOTTOM_LEFT,
    })
  }
  handleResponse(res, dispatch, INVITE_USER, ERROR_INVITE_USER)
}

export const inviteUser = (formData) => (dispatch) => {
  dispatch({ type: INVITE_USER_LOADING, payload: true });
  api
    .postRequest("/iam/user/invite", formData)
    .then((res) => handleInviteResponse(res, dispatch))
    .catch((err) => handleError(err, dispatch, ERROR_INVITE_USER));
};

const handleResendInviteResponse = (res, dispatch) => {
  if (res.status == 200) {
    toast.success("Invite resent successfully", {
      position: toast.POSITION.BOTTOM_LEFT,
    })
  }
  handleResponse(res, dispatch, RESEND_INVITE, ERROR_RESEND_INVITE)
}

export const resendInvite = (formData) => (dispatch) => {
  dispatch({ type: RESEND_INVITE_LOADING, payload: true });
  api
    .postRequest("/iam/user/resendInvite", formData)
    .then((res) => handleResendInviteResponse(res, dispatch))
    .catch((err) => handleError(err, dispatch, ERROR_RESEND_INVITE));
};

export const verifyUser = (formData) => (dispatch) => {
  api
    .postRequestOut("/iam/user/verify/v2", formData)
    .then((res) => handleResponse(res, dispatch, VERIFY_USER, ""))
    .catch((err) => handleError(err, dispatch, ""));
};

export const resetPassword = (formData) => (dispatch) => {
  api
    .putRequest("/iam/user/recovery/v2", formData)
    .then((res) => handleResponse(res, dispatch, RESET_PASSWORD, ERROR_RESET_PASSWORD))
    .catch((err) => handleError(err, dispatch, ERROR_RESET_PASSWORD));
};

export const getOrganisationList = () => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/iam/ou/derived`)
    .then((res) => handleResponse(res, dispatch, GET_ORGANISATIONS, ERROR_GET_ORGANISATIONS))
    .catch((err) => handleError(err, dispatch, ERROR_GET_ORGANISATIONS));
};

export const getServiceAccounts = (parameters) => (dispatch) => {
  if (_.isEmpty(parameters?.dpIds)) return;
  let _parameters: any = {
      dpIds: parameters.dpIds.map(dpId => `'${dpId}'`).join(','),
      searchText: parameters.searchText,
      sortBy: parameters.sortBy,
      sortOrder: parameters.sortOrder,
      limit: parameters.limit,
      offset: (parameters.page - 1) * parameters.limit
  };
  const data = {
      query: `GET_SERVICE_ACCOUNTS`,
      named: true,
      format: 'csv',
      parameters: _parameters
  }
  api
      .postRequestOut(`/store/pg/query`, data)
      .then((res) => {
          handleResponse(res, dispatch, NEW_SUMMARY_SERVICE_ACCOUNTS)
      })
      .catch((err) => {
          handleError(err, dispatch);
      });
}


export const getOrganisationUnitList = (searchValue = "", hasDevices = "true") => (dispatch) => {
  api
    .getRequest(`/iam/v3/ou/derived?search=${searchValue}&sort=name&sortOrder=asc`)
    .then((res) => handleResponse(res, dispatch, GET_ORGANISATIONS_UNITS, ERROR_GET_ORGANISATIONS_UNITS))
    .catch((err) => handleError(err, dispatch, ERROR_GET_ORGANISATIONS_UNITS));
};

export const getSearchedOrgList = (searchValue = "") => (dispatch) => {
  api
    .getRequest(`/iam/v3/ou/derived?search=${searchValue}&sort=name&sortOrder=asc`)
    .then((res) => handleResponse(res, dispatch, GET_SEARCH_DP_LIST, ERROR_GET_ORGANISATIONS_UNITS))
    .catch((err) => handleError(err, dispatch, ERROR_GET_ORGANISATIONS_UNITS));
};

export const ouDerived = (loggedInUser) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequestOut(`/iam/ou/derived?loggedInUser=${loggedInUser}`)
    .then((res) => handleResponse(res, dispatch, OU_DERIVED))
    .catch((err) => handleError(err, dispatch));
};

export const fetchResources = (name: string, page: number, limit: number, serviceName:string, serviceFunction: string) => (dispatch) => {
  dispatch({ type: SET_FETCH_OU_RESOURCES_LOADING, payload: true });
  api
    .getRequestOut(`/edge/distributionPartners/dpName/${name}/locations?service=${serviceName}&service_function=${serviceFunction}`)
    .then((res) => handleResponse(res, dispatch, FETCH_OU_RESOURCES, ERROR_FETCH_OU_RESOURCES, SET_FETCH_OU_RESOURCES_LOADING))
    .catch((err) => handleError(err, dispatch, ERROR_FETCH_OU_RESOURCES));
};

export const fetchServiceLines = (dpId: string) => (dispatch) => {
  dispatch({ type: SET_FETCH_OU_RESOURCES_LOADING, payload: true });
  api
    .getRequestOut(`/iam/starlink/serviceLine/dpId/${dpId}`)
    .then((res) => handleResponse(res, dispatch, FETCH_SERVICE_LINES, ERROR_FETCH_SERVICE_LINES, SET_FETCH_OU_RESOURCES_LOADING))
    .catch((err) => handleError(err, dispatch, ERROR_FETCH_SERVICE_LINES));
};

export const organizationpolicy = (ouName) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequestOut(`/iam/organizationpolicy/displayApplicableUserPolicies?ouName=${encodeURIComponent(ouName)}`)
    .then((res) => handleResponse(res, dispatch, OU_POLICY))
    .catch((err) => handleError(err, dispatch));
};

export const userdetch = (formData) => (dispatch) => {
  dispatch({ type: USER_DETACH_LOADING, payload: true });
  api
    .deleteRequest("/iam/organizationuser/detach", formData)
    .then((res) => handleResponse(res, dispatch, USER_DETACH, ERROR_DETACH_USER))
    .catch((err) => handleError(err, dispatch, ERROR_DETACH_USER));
};

const handleUpdateUserResponse = (res, dispatch) => {
  if (res.status == 200) {
    toast.success("Updated successfully", {
      position: toast.POSITION.BOTTOM_LEFT,
    })
  }
  handleResponse(res, dispatch, UPDATE_USER)
}

export const UpdateUser = (formData) => (dispatch) => {
  dispatch({ type: UPDATE_USER_LOADING, payload: true });
  api
    .postRequest("/iam/policy/updatePolicies", formData)
    .then((res) => handleUpdateUserResponse(res, dispatch))
    .catch((err) => handleError(err, dispatch));
};

// Update user organization for kapturev2 api
const handleUpdateUserOrganizationResponse = (res, dispatch) => {
  if (res.status == 200 && res.data.status == 1) {
    toast.success(res.data.message, {
      position: toast.POSITION.BOTTOM_LEFT,
    })
  } else {
    toast.error(res.data.message, {
      position: toast.POSITION.BOTTOM_LEFT,
    })
  }
  handleResponse(res, dispatch, UPDATE_USER_ORGANIZATION)
}

export const updateUserOrganization = (formData) => (dispatch) => {
  dispatch({ type: UPDATE_USER_ORGANIZATION_LOADING, payload: true });
  api
      .postRequestForExternalApi(`${KAPTUREV2_URL_INV}dp/moveUser`, formData)
      .then((res) => handleUpdateUserOrganizationResponse(res, dispatch))
    .catch((err) => handleError(err, dispatch));
};

const handleEnableUserResponse = (res, dispatch) => {
  if (res.status == 200) {
    toast.success("User enabled successfully", {
      position: toast.POSITION.BOTTOM_LEFT,
    })
  }
  handleResponse(res, dispatch, ENABLE_USER_ACCOUNT, ERROR_ENABLE_USER_ACCOUNT)
}

export const enableUserAccount = (formData) => (dispatch) => {
  dispatch({ type: ENABLE_USER_ACCOUNT_LOADING, payload: true });
  api
    .patchRequest("/iam/user/enableUserAccount", formData)
    .then((res) => handleEnableUserResponse(res, dispatch))
    .catch((err) => handleError(err, dispatch, ERROR_ENABLE_USER_ACCOUNT));
};

const handleDisableUserResponse = (res, dispatch, text) => {
  if (res.status == 200) {
    toast.success(text, {
      position: toast.POSITION.BOTTOM_LEFT,
    })
  }
  handleResponse(res, dispatch, DISABLE_USER_ACCOUNT, ERROR_DISABLE_USER_ACCOUNT)
}

export const disableUserAccount = (formData) => (dispatch) => {
  dispatch({ type: DISABLE_USER_ACCOUNT_LOADING, payload: true });
  api
    .patchRequest("/iam/user/disableUserAccount", formData)
    .then((res) => handleDisableUserResponse(res, dispatch, "User disabled successfully"))
    .catch((err) => handleError(err, dispatch, ERROR_DISABLE_USER_ACCOUNT));
};

export const enableDisable2Fa = (formData) => (dispatch) => {
  dispatch({ type: DISABLE_USER_ACCOUNT_LOADING, payload: true });
  api
    .patchRequest("/iam/user/update2FA", formData)
    .then((res) => handleDisableUserResponse(res, dispatch, formData?.enabled ? "Enabled Two factor Authentication": "Disabled Two Factor Authentication"))
    .catch((err) => handleError(err, dispatch, ERROR_DISABLE_USER_ACCOUNT));
};

const handlePasswordResponse = (res, dispatch) => {
  if (res.status == 200) {
    toast.success("Password has been set successfully", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  }
  handleResponse(res, dispatch, SET_PASSWORD_User, ERROR_SET_PASSWORD_User)
}

export const setPasswordUser = (formData) => (dispatch) => {
  dispatch({ type: SET_PASSWORD_User_LOADING, payload: true });
  api
    .patchRequest("/iam/user/setPassword", formData)
    .then((res) => handlePasswordResponse(res, dispatch))
    .catch((err) => handleError(err, dispatch, ERROR_SET_PASSWORD_User));
};

export const detachResource = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .postRequest("/iam/ou/resources/dettach", formData)
    .then((res) => handleResponse(res, dispatch, DETACH_RESOURCE_OU, ERROR_DETACH_RESOURCE_OU))
    .catch((err) => handleError(err, dispatch, ERROR_DETACH_RESOURCE_OU));
};

export const notAttachedOuResources = (name: string) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/iam/ou/resources/attach?name=${name}`)
    .then((res) => handleResponse(res, dispatch, NOT_ATTACH_RESOURCE_OU, ERROR_NOT_ATTACH_RESOURCE_OU))
    .catch((err) => handleError(err, dispatch, ERROR_NOT_ATTACH_RESOURCE_OU));
};

export const attachResource = (formData) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .postRequest("/iam/ou/resources/attach", formData)
    .then((res) => handleResponse(res, dispatch, ATTACH_RESOURCE_OU, ERROR_ATTACH_RESOURCE_OU))
    .catch((err) => handleError(err, dispatch, ERROR_ATTACH_RESOURCE_OU));
};

export const allTemplate = (ouName: string, isTemplate: string) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  api
    .getRequest(`/iam/oupolicy?ouName=${encodeURIComponent(ouName)}&isTemplate=${isTemplate}`)
    .then((res) => handleResponse(res, dispatch, ALL_TEMPLATE))
    .catch((err) => handleError(err, dispatch));
};

const handleUserDeleteResponse = (res, dispatch) => {
  if (res.status == 200) {
    toast.success("User deleted successfully", {
      position: toast.POSITION.BOTTOM_LEFT,
    })
  }
  handleResponse(res, dispatch, USER_DELETE, ERROR_DETACH_USER)
}

export const userDelete = (paylod: any) =>(dispatch) =>{
  dispatch({ type: USER_DELETE_LOADING, payload: true });
  api
    .deleteRequest("/iam/v2/user", paylod)
    .then((res) => handleUserDeleteResponse(res, dispatch))
    .catch((err) => handleError(err, dispatch, ERROR_DETACH_USER));
}

const showToastMessage = (res, dispatch, handler) => {
  if (res?.data?.status == 1) {
    toast.success(res?.data?.message, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  }
  handleResponse(res, dispatch, handler)
}

export const accountCreateSubDP = (dpName, dpFullName, parentDp) => (dispatch) => {
  dispatch({ type: SET_SUB_DP_CREATE_LOADING, payload: true });
  let payload = {
    DPName: dpName,
    DPFullName: dpFullName,
    parentName: parentDp
  }
  api
      .postRequestForExternalApi(`${KAPTUREV2_URL_INV}vessels/addDP`, payload)
      .then((res) => showToastMessage(res, dispatch, ACCOUNT_CREATE_SUB_DP))
      .catch((err) => handleError(err, dispatch));
};

export const accountDeleteOrganization = (dpId) => (dispatch) => {
  dispatch({ type: SET_DELETE_ORG_LOADING, payload: true });
  api
      .deleteRequestForExternalApi(`${KAPTUREV2_URL_INV}dp/deleteSubDP?k4DpId=${dpId}`)
      .then((res) => showToastMessage(res, dispatch, GET_DELETE_ORG))
      .catch((err) => handleError(err, dispatch));
};

const handleModifyOrgResponse = (res, dispatch) => {
  if (res?.status == 200 && res?.data?.status == 1) {
    toast.success("Organization Name Updated successfully", {
      position: toast.POSITION.BOTTOM_LEFT,
    })
  } else {
    toast.error(res?.data?.message, {
      position: toast.POSITION.BOTTOM_LEFT,
    })
  }
  handleResponse(res, dispatch, GET_MODIFY_ORG)
}

export const accountModifyOrganization = (payload) => (dispatch) => {
  dispatch({ type: SET_MODIFY_ORG_LOADING, payload: true });
  api
      .postRequestForExternalApi(`${KAPTUREV2_URL_INV}dp/updateOneDp`, payload)
      .then((res) => handleModifyOrgResponse(res, dispatch))
      .catch((err) => handleError(err, dispatch));
};

export const getOrganisationInfo = (dpName) => (dispatch) => {
  dispatch({ type: SET_DP_INFO_LOADING, payload: true });
  api
      .getRequestForExternalApi(`${KAPTUREV2_URL_INV}dp/data?dpName=${dpName}`)
      .then((res) => showToastMessage(res, dispatch, GET_DP_INFO))
      .catch((err) => handleError(err, dispatch));
};

// Login History
export const getIamLoginHistoryTable = (startDate, endDate, search, page, limit, sort, sortOrder, selectedOu, users, apps, desc, card) => (dispatch) => {
  dispatch({ type: SET_IAM_LOGIN_HISTORY_TABLE, payload: true });
  
  let Users:any = [];
  users?.forEach((el, i) => {
    Users.push(`'${el.user_name}'`);
  })
  let Apps:any = [];
  apps?.forEach((el, i) => {
      Apps.push(`'${el.application_name}'`);
    })
    let Desc:any = [];
    desc?.forEach((el, i) => {
      Desc.push(`'${el.description}'`);
    })
    let SelectedOu:any = [];
    selectedOu?.forEach((el, i) => {
      if(el) {
        SelectedOu.push(`'${el}'`);
      }
    })

  const tableData: any = {
      query: 'IAM_LOGIN_HISTORY_TABLE',
      named: true,
      parameters: {
          startDate: startDate,
          endDate: endDate,
          search: search ? search : '',
          sort: sort,
          sortOrder: sortOrder,
          pageStart: ((page-1)*limit)+1,
          pageLimit: (page*limit),
          user_filt: users[0]?.user_name ? `and user_name in (${Users})` : '',
          app_filt: apps[0]?.application_name ? `and application_name in (${Apps})` : '',
          group_filt: selectedOu?.length > 0 ? `and dp_id in (${SelectedOu})` : '',
          description: desc[0]?.description ? `and description in (${Desc})` : '',
          topwidg_filt: card?.type && card?.check ? `and request_type = '${card.type}' and logged_in = '${card.check}'` : card?.type ? `and request_type = '${card.type}'` : ''
      },
      format: 'csv'
  }
  api
      .postRequestOut(`/store/ch/query`, tableData)
      .then((res) => handleResponse(res, dispatch, GET_IAM_LOGIN_HISTORY_TABLE))
      .catch((err) => handleError(err, dispatch));
};

export const getIamLoginHistortTableCount = (startDate, endDate, search, selectedOu, users, apps, desc, card) => (dispatch) => {
  dispatch({ type: SET_IAM_LOGIN_HISTORY_TABLE_COUNT, payload: true });

  let Users:any = [];
  users?.forEach((el, i) => {
      Users.push(`'${el.user_name}'`);
  })
  let Apps:any = [];
  apps?.forEach((el, i) => {
      Apps.push(`'${el.application_name}'`);
  })
  let Desc:any = [];
  desc?.forEach((el, i) => {
      Desc.push(`'${el.description}'`);
  })
  let SelectedOu:any = [];
  selectedOu?.forEach((el, i) => {
    if(el) {
      SelectedOu.push(`'${el}'`);
    }
  })

  const tableCount: any = {
      query: 'IAM_LOGIN_HISTORY_TABLE_TOTAL',
      named: true,
      parameters: {
          startDate: startDate,
          endDate: endDate,
          search: search ? search : '',
          user_filt: users[0]?.user_name ? `and user_name in (${Users})` : '',
          app_filt: apps[0]?.application_name ? `and application_name in (${Apps})` : '',
          group_filt: selectedOu?.length > 0 ? `and dp_id in (${SelectedOu})` : '',
          description: desc[0]?.description ? `and description in (${Desc})` : '',
          topwidg_filt: card?.type && card?.check ? `and request_type = '${card.type}' and logged_in = '${card.check}'` : card?.type ? `and request_type = '${card.type}'` : ''
      },
      format: 'csv'
  }
  api
      .postRequestOut(`/store/ch/query`, tableCount)
      .then((res) => handleResponse(res, dispatch, GET_IAM_LOGIN_HISTORY_TABLE_COUNT))
      .catch((err) => handleError(err, dispatch));
};

export const getIamLoginHistoryTopWidget = (startDate, endDate, search, selectedOu, users, apps, desc, card) => (dispatch) => {
  dispatch({ type: SET_IAM_LOGIN_HISTORY_TOP_WIDGET, payload: true });

  let Users:any = [];
  users?.forEach((el, i) => {
      Users.push(`'${el.user_name}'`);
  })
  let Apps:any = [];
  apps?.forEach((el, i) => {
      Apps.push(`'${el.application_name}'`);
  })
  let Desc:any = [];
  desc?.forEach((el, i) => {
      Desc.push(`'${el.description}'`);
  })
  let SelectedOu:any = [];
    selectedOu?.forEach((el, i) => {
      if(el) {
        SelectedOu.push(`'${el}'`);
      }
  })

  const topWidgetData: any = {
      query: 'IAM_LOGIN_HISTORY_TOPWIDGET',
      named: true,
      parameters: {
          startDate: startDate,
          endDate: endDate,
          search: search ? search : '',
          user_filt: users[0]?.user_name ? `and user_name in (${Users})` : '',
          app_filt: apps[0]?.application_name ? `and application_name in (${Apps})` : '',
          group_filt: selectedOu?.length > 0 ? `and dp_id in (${SelectedOu})` : '',
          description: desc[0]?.description ? `and description in (${Desc})` : '',
      },
      format: 'csv'
  }
  api
      .postRequestOut(`/store/ch/query`, topWidgetData)
      .then((res) => handleResponse(res, dispatch, GET_IAM_LOGIN_HISTORY_TOP_WIDGET))
      .catch((err) => handleError(err, dispatch));
};

export const getIamLoginHistoryFilterUser = (startDate, endDate, search) => (dispatch) => {
  dispatch({ type: SET_IAM_LOGIN_HISTORY_FILTER_USER, payload: true });
  const userNames: any = {
      query: 'IAM_LOGIN_HISTORY_FILTER_USER',
      named: true,
      parameters: {
          startDate: startDate,
          endDate: endDate,
          search: search ? search : ''
      },
      format: 'csv'
  }
  api
      .postRequestOut(`/store/ch/query`, userNames)
      .then((res) => handleResponse(res, dispatch, GET_IAM_LOGIN_HISTORY_FILTER_USER))
      .catch((err) => handleError(err, dispatch));
};

export const getIamLoginHistoryFilterApp = (startDate, endDate, search) => (dispatch) => {
  dispatch({ type: SET_IAM_LOGIN_HISTORY_FILTER_APP, payload: true });
  const appNames: any = {
      query: 'IAM_LOGIN_HISTORY_FILTER_APP',
      named: true,
      parameters: {
          startDate: startDate,
          endDate: endDate,
          search: search ? search : ''
      },
      format: 'csv'
  }
  api
      .postRequestOut(`/store/ch/query`, appNames)
      .then((res) => handleResponse(res, dispatch, GET_IAM_LOGIN_HISTORY_FILTER_APP))
      .catch((err) => handleError(err, dispatch));
};


export const getClickhouseBucketReformat = (interval) => {
  switch (interval) {
      case "5m": return '5 minute';
      case "10m": return '10 minute';
      case "15m": return '15 minute';
      case "30m": return '30 minute';
      case "1h": return '1 hour';
      case "2h": return '2 hour';
      case "3h": return '3 hour';
      case "4h": return '4 hour';
      case "6h": return '6 hour';
      case "12h": return '12 hour';
      case "1d": return '1 day';
      case "7d": return '7 day';
      default: return '1 hour';
  }
}

export const getIamLoginHistoryTimeseriesChart = (startDate, endDate, search, interval, selectedOu, users, apps, desc, card) => (dispatch) => {
  dispatch({ type: SET_IAM_LOGIN_HISTORY_TIMESERIES_CHART, payload: true });

  let Users:any = [];
  users?.forEach((el, i) => {
      Users.push(`'${el.user_name}'`);
  })
  let Apps:any = [];
  apps?.forEach((el, i) => {
      Apps.push(`'${el.application_name}'`);
  })
  let Desc:any = [];
  desc?.forEach((el, i) => {
      Desc.push(`'${el.description}'`);
  })
  let SelectedOu:any = [];
    selectedOu?.forEach((el, i) => {
      if(el) {
        SelectedOu.push(`'${el}'`);
      }
  })

  const lineChartData: any = {
      query: 'IAM_LOGIN_HISTORY_TIMESERIES_CHART',
      named: true,
      parameters: {
          startDate: startDate,
          endDate: endDate,
          search: search ? search : '',
          bucketInterval: getClickhouseBucketReformat(interval),
          user_filt: users[0]?.user_name ? `and user_name in (${Users})` : '',
          app_filt: apps[0]?.application_name ? `and application_name in (${Apps})` : '',
          group_filt: selectedOu?.length > 0 ? `and dp_id in (${SelectedOu})` : '',
          description: desc[0]?.description ? `and description in (${Desc})` : '',
          topwidg_filt: card?.kpi ? `where kpi in ('${card.kpi}')` : ''
      },
      format: "chart",
      tsColumn: "tb",
      series: "value",
      groupBy: "kpi"
  }
  api
      .postRequestOut(`/store/ch/query`, lineChartData)
      .then((res) => handleResponse(res, dispatch, GET_IAM_LOGIN_HISTORY_TIMESERIES_CHART))
      .catch((err) => handleError(err, dispatch));
};

export const getIamLoginHistoryFilterDescList = (startDate, endDate, selectedOu, users, apps) => (dispatch) => {
  dispatch({ type: SET_IAM_LOGIN_HISTORY_FILTER_DESC_LIST, payload: true });

  let SelectedOu:any = [];
    selectedOu?.forEach((el, i) => {
      if(el) {
        SelectedOu.push(`'${el}'`);
      }
  })
  let Users:any = [];
  users?.forEach((el, i) => {
      Users.push(`'${el.user_name}'`);
  })
  let Apps:any = [];
  apps?.forEach((el, i) => {
      Apps.push(`'${el.application_name}'`);
  })

  const appNames: any = {
      query: 'IAM_LOGIN_HISTORY_TABLE_DESCRI_LIST',
      named: true,
      parameters: {
          startDate: startDate,
          endDate: endDate,
          user_filt: users[0]?.user_name ? `and user_name in (${Users})` : '',
          app_filt: apps[0]?.application_name ? `and application_name in (${Apps})` : '',
          group_filt: selectedOu?.length > 0 ? `and dp_id in (${SelectedOu})` : '',
      },
      format: 'csv'
  }
  api
      .postRequestOut(`/store/ch/query`, appNames)
      .then((res) => handleResponse(res, dispatch, GET_IAM_LOGIN_HISTORY_FILTER_DESC_LIST))
      .catch((err) => handleError(err, dispatch));
};


export const fetchIsPrivateOu = () => (dispatch) => {
  dispatch({ type: IS_PRIVATE_LOADING, payload: true });
  dispatch({ type: IS_PRIVATE_ENABLED, payload: false });
  api
    .getRequest(`/iam/ou/getDeployedPrivateFlag`)
    .then((res) => {
      if("deployed private flag fetched" === res?.data?.message) {
        let isPrivate = res?.data?.data;
        localStorage.setItem("IS_PRIVATE_ENABLED", isPrivate);
        dispatch({ type: IS_PRIVATE_LOADING, payload: false });
        dispatch({ type: IS_PRIVATE_ENABLED, payload: isPrivate });
      }
    })
    .catch((err) => {
      dispatch({ type: IS_PRIVATE_ENABLED, payload: false });
      console.log(err)
    })
};


